import React, { ReactNode } from 'react';

type ButtonProps = {
  alternate?: boolean;
  children: ReactNode;
};

const Button = (props: ButtonProps) => {
  return (
    <button
      className={
        'px-4 py-1 font-sans mt-2 rounded-md text-white hover:bg-gray-600 ' +
        (props.alternate ? 'bg-slate-700' : 'bg-c1')
      }
    >
      {props.children}
    </button>
  );
};

const Card = () => {
  return (
    <article className="shadow bg-white sm:flex">
      <div className="w-full h-32 sm:h-full sm:max-w-[200px] bg-c1 shadow"></div>
      <div className="p-4">
        <h3 className="text-xl underline-offset-2 underline decoration-4 decoration-c1 mb-1">Title</h3>
        <p className="text-gray-600">Subheading</p>
        <a href="/" className="btn-alt mt-2">
          Click me!
        </a>
      </div>
    </article>
  );
};

const Test1Index = () => {
  return (
    <div className="container mx-auto bg-slate-600 p-4">
      <section className="grid lg:grid-cols-2 gap-4">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </section>
    </div>
  );
};

export default Test1Index;
